import { createRouter, createWebHistory } from 'vue-router'
import { InfirmaryRoutes } from './modules/infirmary/router'

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: '',
    component: () => import('./layouts/main/Main.vue'),
    children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path: '',
        redirect: '/dashboard/home',
        meta: {
          authRequired: true
        }
      },
      {
        path: '/dashboard/home',
        name: 'dashboard.home',
        component: () => import('./views/dashboard/Dashboard.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/reports/home',
        name: 'reports.home',
        component: () => import('./views/reports/ReportsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/reports/application-usage-report',
        name: 'application-usage-report',
        component: () => import('./views/reports/ApplicationUsageReport.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/evaluation/percentage',
        name: 'evaluation.percentage',
        component: () => import('./views/reports/EvaluationPercentage.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/user/profile',
        name: 'user',
        component: () => import('./views/pages/user-settings/UserSettings.vue'),
      },
      {
        path: '/users/list/:userType',
        name: 'users.list',
        component: () => import('./views/users/Users.vue'),
      },
      {
        path: '/users/list/:userType/add',
        name: 'users.add',
        component: () => import('./views/users/AddUser.vue'),
      },
      {
        path: '/users/list/:userType/edit/:id',
        name: 'users.edit',
        component: () => import('./views/users/EditUser.vue'),
      },
      {
        path: '/users/list/:userType/view/:id',
        name: 'users.details',
        component: () => import('./views/users/UserDetails.vue'),
      },
      {
        path: '/substituteprofessors',
        name: 'substituteprofessors',
        component: () => import('./views/subsituteprofessors/SubsituteProfessorsList.vue'),
      },
      {
        path: '/classes/list',
        name: 'classes.list',
        component: () => import('./views/classes/ClassesList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/absences/list',
        name: 'absences.list',
        component: () => import('./views/absences/AbsencesList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/absences-informed/list',
        name: 'absences_informed.list',
        component: () => import('./views/absences/AbsencesInformedList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/remarks/list',
        name: 'remarks.list',
        component: () => import('./views/remarks/RemarksList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/announcements/list',
        name: 'announcements.list',
        component: () => import('./views/announcements/AnnouncementsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/announcements/add',
        name: 'announcements.add',
        component: () => import('./views/announcements/AddAnnouncement.vue'),
      },
      {
        path: '/announcements/edit/:id',
        name: 'announcements.edit',
        component: () => import('./views/announcements/EditAnnouncement.vue'),
      },
      {
        path: '/announcements/view/:id',
        name: 'announcements.details',
        component: () => import('./views/announcements/AnnouncementDetails.vue'),
      },
      {
        path: '/daily-professor/list',
        name: 'dailyprofessor.list',
        component: () => import('./views/dailyprofessor/DailyProfessorList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/academic-year/list',
        name: 'academic_year.list',
        component: () => import('./views/academicyear/AcademicYearList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/classes/details/:id',
        name: 'classes.details',
        component: () => import('./views/classes/ClassDetails.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/subjects/list',
        name: 'subjects.list',
        component: () => import('./views/subjects/SubjectsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/teaching-units/list',
        name: 'teaching_unit.list',
        component: () => import('./views/teachingunits/TeachingUnitsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/yearly-teaching-units/list',
        name: 'yearly_teaching_unit.list',
        component: () => import('./views/teachingunits/YearlyTeachingUnitsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/units/list',
        name: 'units.list',
        component: () => import('./views/units/UnitsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/units/details/:id',
        name: 'units.details',
        component: () => import('./views/units/UnitDetails.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/import',
        name: 'importing',
        component: () => import('./views/importing/Importing.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/evaluation',
        name: 'evaluation',
        component: () => import('./views/evaluation/EvaluationList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/evaluation/details/:class_id/:class_number/:subject_id',
        name: 'evaluation.details',
        component: () => import('./views/evaluation/EvaluationDetails.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/evaluation-configuration',
        name: 'evaluation_configuration',
        component: () => import('./views/evaluationconfiguration/EvaluationConfigurationList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/evaluation-methods',
        name: 'evaluation_methods',
        component: () => import('./views/evaluationmethods/EvaluationMethodsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/caretaker/evaluation',
        name: 'caretaker_evaluation',
        component: () => import('./views/profiles/caretaker/Evaluation.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/competences',
        name: 'competences',
        component: () => import('./views/competences/CompetencesList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/competences/details/:class_id/:subject_id',
        name: 'competences.details',
        component: () => import('./views/competences/components/CompetenceDetails.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/competences-configuration',
        name: 'competences_configuration',
        component: () => import('./views/competences/CompetencesConfigurationList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/schedule-configuration',
        name: 'shifttime',
        component: () => import('./views/schedule/ShiftTimeConfigurationList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/schedule',
        name: 'schedule',
        component: () => import('./views/schedule/ScheduleList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/contracts',
        name: 'contracts',
        component: () => import('./views/studentcontracts/StudentContractsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/student-comments',
        name: 'student-comments',
        component: () => import('./views/studentcomments/StudentCommentsList.vue'),
        meta: {
          authRequired: true
        }
      },
      {
        path: '/auditing',
        name: 'logs.list',
        component: () => import('./views/auditing/AuditingList.vue'),
        meta: {
          authRequired: true
        }
      },
      // Infirmary routes
      ...InfirmaryRoutes,
    ]
  },

  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/full-page/FullPage.vue'),
    children: [
      // =============================================================================
      // PAGES
      // =============================================================================
      {
        path: '/pages/login',
        name: 'page.login',
        component: () => import('@/views/pages/login/Login.vue'),
        meta: {
        }
      },
      {
        path: '/pages/forgot-password',
        name: 'page.forgot-password',
        component: () => import('@/views/pages/ForgotPassword.vue'),
        meta: {
        }
      },
      {
        path: '/pages/reset-password',
        name: 'page.reset-password',
        component: () => import('@/views/pages/ResetPassword.vue'),
        props: (route) => ({ token: route.query.token || null }), // Extract the 'token' from the query and make it a prop
        meta: {}
      },
      {
        path: '/pages/lock-screen',
        name: 'page.lock-screen',
        component: () => import('@/views/pages/LockScreen.vue'),
        meta: {
        }
      },
      {
        path: '/pages/comingsoon',
        name: 'page.coming-soon',
        component: () => import('@/views/pages/ComingSoon.vue'),
        meta: {
        }
      },
      {
        path: '/pages/error-404',
        name: 'page.error-404',
        component: () => import('@/views/pages/Error404.vue'),
        meta: {
        }
      },
      {
        path: '/pages/error-500',
        name: 'page.error-500',
        component: () => import('@/views/pages/Error500.vue'),
        meta: {
        }
      },
      {
        path: '/pages/not-authorized',
        name: 'page.not-authorized',
        component: () => import('@/views/pages/NotAuthorized.vue'),
        meta: {
        }
      },
      {
        path: '/pages/maintenance',
        name: 'page.maintenance',
        component: () => import('@/views/pages/Maintenance.vue'),
        meta: {
        }
      }
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: '/:pathMatch(.*)*',
    redirect: '/pages/error-404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.afterEach(() => {
      // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router