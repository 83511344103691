// en, de, fr, pt
export default {
  al: {
    // smn
    Modules: 'Modulet',
    Configuration: 'Konfigurimi',
    Importing: 'Importimi',
    Users: 'Përdoruesit',
    Students: 'Nxënësit',
    Professors: 'Mësimdhënësit',
    Administration: 'Administrata',
    SuperAdmin: 'Super Administratori',
    Admin: 'Administratori',
    Management: 'Drejtoria',
    Caretakers: 'Prindërit',
    LowManagement: 'Menaxhmenti i mesëm',
    Subjects: 'Lëndët',
    Classes: 'Klasat',
    AcademicYear: 'Vitet akademike',
    TeachingUnit: 'Planprogrami',
    YearlyTeachingUnit: 'Planprogrami vjetor',
    Unit: 'Orët Mësimore',
    Evaluation: 'Vlerësimi',
    Absence: 'Mungesat',
    Remarks: 'Vërejtjet',
    Announcements: 'Njoftimet',
    AbsenceInformed: 'Të njoftuara',
    AbsenceAll: 'Të gjitha',
    EvaluationConfiguration: 'Konfigurimi i vlerësimit',
    EvaluationMethods: 'Metodat e vlerësimit',
    CaretakerStudentEvaluation: 'Vlerësimi',
    SubstituteProfessors: "Zëvendësimet",
    DailyProfessor: 'Kujdestaria ditore',
    Competences: 'Vlerësimi për shkallë',
    CompetenceConfiguration: 'Vlerësimi për shkallë',
    Schedule: "Orari",
    ShiftTimeConfiguration: "Konfigurimi orarit",
    Contracts: "Kontratat për shkollim",
    //

    //
    Dashboard: 'Kryefaqja',
    Home: 'Ballina',

    Reports: 'Raportet',
    ReportsHome: 'Ballina',
    ReportsEvaluationPercentage: 'Vlerësimi ne përqindje',
    Infirmary: 'Infermeria',
    HealthCards: 'Kartelat shëndetësore',
    OverviewHealthCards: 'Pasqyra e kartelave shëndetësore',
    Therapies: 'Terapitë',
    InfirmaryReports: 'Raportet për infermerinë',
    InfirmaryRole: 'Infermieret',
    ApplicationUsageReport: 'Raport në përdorimin e aplikacionit',
    StudentComments: "Komentet për nxënës",
    AuditingList: "Auditimi",
  },
  en: {
    // smn
    Modules: 'Modules',
    Configuration: 'Configuration',
    Importing: 'Importing',
    Users: 'Users',
    Students: 'Students',
    Professors: 'Professors',
    Administration: 'Administration',
    SuperAdmin: 'Super Administrator',
    Admin: 'Administrator',
    Management: 'Management',
    Caretakers: 'Caretakers',
    LowManagement: 'Middle management',
    Subjects: 'Subjects',
    Classes: 'Classes',
    AcademicYear: 'Academic year',
    TeachingUnit: 'Teaching unit',
    YearlyTeachingUnit: 'Yearly teaching units',
    Unit: 'Unit',
    Evaluation: 'Evaluation',
    Absence: 'Absences',
    Remarks: 'Remarks',
    Announcements: 'Announcements',
    AbsenceInformed: 'Informed',
    AbsenceAll: 'All',
    EvaluationConfiguration: 'Evaluation',
    EvaluationMethods: 'Evaluation methods',
    CaretakerStudentEvaluation: 'Evaluation',
    SubstituteProfessors: "Substitute professors",
    DailyProfessor: 'Daily professor',
    Competences: 'Competences',
    CompetenceConfiguration: 'Competences',
    Schedule: "Schedule",
    ShiftTimeConfiguration: "Schedule configuration",
    Contracts: "Tuition contracts",
    //
    Dashboard: 'Dashboard',
    Home: 'Home',

    Reports: 'Reports',
    ReportsHome: 'Reports home',
    ReportsEvaluationPercentage: 'Percentage evaluation',
    Infirmary: 'Infirmary',
    HealthCards: 'Health cards',
    OverviewHealthCards: 'Overview health cards',
    Therapies: 'Therapies',
    InfirmaryReports: 'Infirmary reports',
    InfirmaryRole: 'Nurses',
    ApplicationUsageReport: 'Application usage report',
    StudentComments: "Student comments",
    AuditingList: "Auditing",
  }
  
}
